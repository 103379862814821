import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import global from './modules/global';
import filters from './modules/filters';

Vue.use(Vuex);

const dataState = createPersistedState({
  paths: ['filters'],
  rehydrated(state) {
    state.dispatch('filters/rehydrate');
  },
});

export default new Vuex.Store({
  modules: {
    global,
    filters,
  },
  strict: true,
  plugins: [dataState],
});
