import { setContext } from 'apollo-link-context';
import { HttpLink } from 'apollo-link-http';
import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import VueApollo from 'vue-apollo';
import { onError } from 'apollo-link-error';
import router from '../router';
import store from '../store';

const middlewareLink = setContext(() => {
  const token = window.localStorage.getItem('_token');
  let headers = {};
  if (token) {
    headers = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
  }
  return headers;
});

const httpLink = middlewareLink.concat(new HttpLink({
  uri: `${window.location.protocol}//${window.location.host}/graphql`,
  fetch,
}));

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(async ({ message, extensions }) => {
      console.log(
        `[GraphQL error]: Message: ${message}`,
      );

      if (extensions.exception.status === 403) {
        window.localStorage.removeItem('_token');
        await store.dispatch('hideSidebar');
        await router.push('/login');
      }
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const client = new ApolloClient({
  link: errorLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: true,
  }),
});

const apolloProvider = new VueApollo({
  defaultClient: client,
});

export default apolloProvider;
