import Vue from 'vue';

export const FILTERS = {
  formatNumber: (number, precision = 0) => {
    if (number) {
      const value = number.toFixed(precision);
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
    }
    return null;
  },
};

export const registerFilters = () => {
  Object.keys(FILTERS).forEach((key) => {
    Vue.filter(key, FILTERS[key]);
  });
};
