const today = new Date();

const state = {
  granularity: 'monthly',
  variant: 'country',
  variant2: {
    'month-completion': 'mtd',
    'month-selection': 'current',
    'show-ads': 'excluded',
  },
  dateRange: [
    new Date(today.getFullYear(), today.getMonth() - 3, today.getDate()),
    new Date(today.getFullYear(), today.getMonth(), today.getDate()),
  ],
  dateRangeFuture: [
    new Date(today.getFullYear(), today.getMonth() - 3, today.getDate()),
    new Date(today.getFullYear(), today.getMonth(), today.getDate()),
  ],
  gridFilter: undefined,
};

const getters = {
  getGranularity: (state) => state.granularity,
  getVariant: (state) => state.variant,
  getVariant2: (state) => state.variant2,
  getDateRange: (state) => state.dateRange,
  getDateRangeFuture: (state) => state.dateRangeFuture,
  getGridFilter: (state) => state.gridFilter,
};

const actions = {
  rehydrate({ state, commit }) {
    const dateRange = [new Date(state.dateRange[0]), new Date(state.dateRange[1])];
    const dateRangeFuture = [new Date(state.dateRangeFuture[0]), new Date(state.dateRangeFuture[1])];
    commit('SET_DATE_RANGE', dateRange);
    commit('SET_DATE_RANGE_FUTURE', dateRangeFuture);
  },
  filterGranularity({ commit }, value) {
    commit('SET_GRANULARITY', value);
  },
  filterVariant({ commit }, value) {
    commit('SET_VARIANT', value);
  },
  filterVariant2({ commit }, value) {
    commit('SET_VARIANT2', value);
  },
  filterDateRange({ commit }, value) {
    commit('SET_DATE_RANGE', value);
  },
  filterDateRangeFuture({ commit }, value) {
    commit('SET_DATE_RANGE_FUTURE', value);
  },
  filterGrid({ commit }, value) {
    commit('SET_GRID_FILTER', value);
  },
};

const mutations = {
  SET_GRANULARITY(state, value) {
    state.granularity = value;
  },
  SET_VARIANT(state, value) {
    state.variant = value;
  },
  SET_VARIANT2(state, value) {
    state.variant2 = {
      ...state.variant2,
      ...value,
    };
  },
  SET_DATE_RANGE(state, value) {
    state.dateRange = value;
  },
  SET_DATE_RANGE_FUTURE(state, value) {
    state.dateRangeFuture = value;
  },
  SET_GRID_FILTER(state, value) {
    state.gridFilter = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
