import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/AppsDashboard'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login'),
  },
  {
    path: '/by-apps',
    name: 'AppsDashboard',
    component: () => import(/* webpackChunkName: "apps-dashboard" */ '../views/AppsDashboard'),
  },
  {
    path: '/month-to-date',
    name: 'MonthToDate',
    component: () => import(/* webpackChunkName: "month-to-date" */ '../views/MonthToDate'),
  },
  {
    path: '/weekly-perf',
    name: 'WeeklyPerf',
    component: () => import(/* webpackChunkName: "weekly-perf" */ '../views/WeeklyPerf'),
  },
  {
    path: '/studio-perf',
    name: 'StudioPerf',
    component: () => import(/* webpackChunkName: "weekly-perf" */ '../views/StudioPerf'),
  },
  {
    path: '/revenue',
    name: 'Revenue',
    component: () => import(/* webpackChunkName: "revenue" */ '../views/Revenue'),
  },
  {
    path: '/spent',
    name: 'Spent',
    component: () => import(/* webpackChunkName: "spent" */ '../views/Spent'),
  },
  {
    path: '/ltv',
    name: 'LTV',
    component: () => import(/* webpackChunkName: "ltv" */ '../views/LTV'),
  },
  {
    path: '/margin',
    name: 'Margin',
    component: () => import(/* webpackChunkName: "margin" */ '../views/Margin'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
