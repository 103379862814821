<template>
  <div id="content">
    <div class="sidebar-page">
      <section class="sidebar-layout">
        <Sidebar v-if="isSidebarVisible" />
        <div class="page-content">
          <router-view />
        </div>
      </section>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import Sidebar from './components/Sidebar';

export default {
  components: {
    Sidebar,
  },
  computed: {
    ...mapGetters('global', ['isSidebarVisible']),
  },
};
</script>

<style lang="scss">

@import "../node_modules/ag-grid-community/src/styles/ag-grid";
@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";

.ag-theme-alpine {
  @include ag-theme-alpine((
    cell-horizontal-border: solid lighten(lightgray, 10%),
    header-column-separator: true,
  ));
}

.ag-theme-alpine .ag-set-filter-list, .ag-theme-alpine .ag-filter-no-matches {
  height: auto;
}

section {
  position:relative;
}

.ag-header-cell-label {
  justify-content: center;
}

#content {
  height: 100vh;
}

.sidebar-page {
  display: flex;
  width: 100%;
  min-height: 100%;

  .sidebar-layout {
    display: flex;
    flex-direction: row;
    min-height: 100%;
    width: 100%;
  }

  .sidebar-wrapper {
    padding: 1rem;

    .title {
      text-align: center;
    }
  }

  .page-content {
    padding: 1rem;
    flex: 1;
    min-width: 1px;
  }
}

.text-muted {
  font-size: 11px;
  color: lightgray;
}

.text-right {
  text-align: right;
}

.title-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

</style>
