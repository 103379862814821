const state = {
  token: null,
  isSidebarVisible: false,
};

const getters = {
  isSidebarVisible: (state) => state.isSidebarVisible,
};

const actions = {
  setToken({ commit }, token) {
    commit('SET_TOKEN', token);
  },
  showSidebar({ commit }) {
    commit('SHOW_SIDEBAR', true);
  },
  hideSidebar({ commit }) {
    commit('SHOW_SIDEBAR', false);
  },
};

const mutations = {
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SHOW_SIDEBAR(state, show) {
    state.isSidebarVisible = show;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
